import React from 'react';

import Layout from "/src/components/Layout/layout";
import HeroSection from '../components/HeroSection/HeroSection'

import BannerImage from '../images/brand-background.png';

const BrandsPage = () => {
    return (
        <Layout>
            <HeroSection
                bannerImage={BannerImage}
            />

        </Layout>
    );
};

export default BrandsPage;
